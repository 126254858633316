import request from '@/utils/request'
import store from '@/store'
import { exportDownload } from '@/api/export'

/**
 * 获取所有角色
 */
export const sysPermissionsList = (data) => {
  return request({
    url: '/sysPermissions/list',
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
      permissionName: data.permissionName
    }
  })
}
export const sysPermissionsDelete = (data) => {
  return request({
    url: '/sysPermissions/delete',
    method: 'POST',
    data
  })
}
export const sysPermissionsGet = (data) => {
  return request({
    url: 'sysPermissions/get',
    params: {
      ...data
    }
  })
}

export const sysPermissionsSave = (data) => {
  return request({
    url: 'sysPermissions/save',
    method: 'POST',
    data
  })
}

export const sysPermissionsGetMenuAssginList = (data) => {
  return request({
    url: 'sysPermissions/getMenuAssginList',
    params: {
      ...data
    }
  })
}
export const sysUserExport = (data) => {
  exportDownload('sysPermissions/export', data)
  // window.location = `${process.env['VUE_APP_BASE_API']}/api/iris/sysPermissions/export?permissionName=${data.permissionName}&token=${store.getters.token}`
}
export const sysOrgGetTree = (data) => {
  return request({
    url: '/sysOrg/getTree',
    params: {
      ...data
    }
  })
}
