import {
  sysOrgGetTree,
  sysPermissionsGet,
  sysPermissionsGetMenuAssginList
} from '@/api/role'

const getPageData = (obj, menuRolePageData, status) => {
  menuRolePageData.loading = true
  sysPermissionsGet({ id: obj.permissionsId }).then((res) => {
    menuRolePageData.loading = false
    if (res && res.code === 200) {
      if (res.data) {
        Object.keys(menuRolePageData.desObj).forEach((item) => {
          menuRolePageData.desObj[item] = res.data[item]
        })
        menuRolePageData.selectOrgTreeData = res.data.orgIds
      }
    }
  })
  sysPermissionsGetMenuAssginList(obj).then((res) => {
    menuRolePageData.loading = false
    if (res.code === 200) {
      if (res.data) {
        if (res.data.list) {
          menuRolePageData.tableData = res.data.list
        } else {
          menuRolePageData.tableData = []
        }
        menuRolePageData.total = res.data.total
      }
    }
  })
}
const getTreeData = (obj, menuRolePageData, status) => {
  sysOrgGetTree({ status: 0, userId: obj.permissionsId }).then((res) => {
    menuRolePageData.loading = false
    if (res.code === 200) {
      if (res.data) {
        menuRolePageData.orgTreeData = res.data
      }
    }
  })
}

export const sysPermissionsGetData = (obj, menuRolePageData, status) => {
  if (status === 'add') {
    getTreeData(obj, menuRolePageData, status)
  } else if (status === 'edit') {
    getPageData(obj, menuRolePageData, status)
    getTreeData(obj, menuRolePageData, status)
  } else if (status === 'details') {
    getPageData(obj, menuRolePageData, status)
  }
}
