<template>
  <div class="roleadd-list-page">
    <PocCardTable class="topPocCardTable selfUser irisePocCardTable">
      <template v-slot:headerTitle>
        <span
          v-if="jumpFlag == 'add' || jumpFlag == 'saveAssign'"
          style="font-weight: 400"
          ><span class="userMan">角色管理</span> / 新增角色</span
        >
        <span v-else-if="jumpFlag == 'edit'" style="font-weight: 400"
          ><span class="userMan">角色管理</span> / 编辑角色</span
        >
        <span v-else-if="jumpFlag == 'copy'" style="font-weight: 400"
          ><span class="userMan">角色管理</span> / 复制角色</span
        >
        <span v-else-if="jumpFlag == 'goDetail'" style="font-weight: 400"
          ><span class="userMan">角色管理</span> / 角色详情</span
        >
      </template>
      <template v-slot:headerGroup>
        <el-button @click="cancle">取消</el-button>
        <el-button type="primary" @click="goEdit" v-if="jumpFlag == 'goDetail'">
          编辑
        </el-button>
        <el-button type="primary" @click="submit" v-else> 保存 </el-button>
      </template>
      <template>
        <!-- 详情的区域 -->
        <div v-if="jumpFlag == 'goDetail'" style="margin-top: 8px">
          <div class="flex-1 flex AlCenter">
            <div class="flex-1 flexBetw">
              <div class="flex-1">
                <span class="lableDetail">角色编码</span>
                <span class="lableContent">{{ selectEditMsg.roleCode }}</span>
              </div>
              <div class="flex-1">
                <span class="lableDetail">角色名称</span>
                <span class="lableContent">{{ selectEditMsg.roleName }}</span>
              </div>
              <div class="flex-1">
                <span class="lableDetail">启用状态</span>
                <span class="lableContent" v-if="selectEditMsg.status == 0"
                  >启用</span
                >
                <span class="lableContent" v-else>停用</span>
              </div>
              <!-- <div class="flex-1">
                <span class="lableDetail ">所属组织</span>
                  <span class="lableContent ellistip">{{orgName}}</span>
                <el-tooltip placement="top">
                  <div slot="content">
                    <div v-for="(item, index) in orgNames" :key="index">
                      {{ item.name }}
                    </div>
                  </div>
                  <svg-icon class="detailsIcon" style="width:14px;height:14px;line-height:14px" icon="details" />
                </el-tooltip>

              </div> -->
            </div>
            <div class="upWord" style="width: 82px"></div>
          </div>
          <div
            class="flex-1 flex AlCenter"
            style="margin-top: 26px; margin-bottom: 12px"
          >
            <div class="flex-1 flexBetw">
              <div class="flex-2">
                <span class="lableDetail">备注</span>
                <span class="lableContent">{{ selectEditMsg.remark }}</span>
              </div>
            </div>
            <div class="upWord" style="background: red; width: 82px"></div>
          </div>
        </div>
        <!-- 新增，编辑，复制等区域 -->
        <PocSlider minheight="70px" maxHeight="300px" btntype="primary" v-else>
          <el-form
            :inline="true"
            size="mini"
            label-position="top"
            class="minWidthInput"
          >
            <el-form-item label="角色编码" label-position="top">
              <el-input
                placeholder="请输入角色编码"
                v-model="selectEditMsg.roleCode"
                :disabled="editCodeFlag"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="角色名称" label-position="right">
              <el-input
                placeholder="请输入角色名称"
                v-model="selectEditMsg.roleName"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="启用状态" label-position="right">
              <el-select
                clearable
                v-model="selectEditMsg.status"
                filterable
                placeholder="请选择启用状态"
                class="industry-selector"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" style="box-sizing: border-box">
              <el-input
                style="width: 575px"
                placeholder="请输入备注"
                v-model="selectEditMsg.remark"
                maxlength="50"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-form>
        </PocSlider>
      </template>
    </PocCardTable>
    <PocCardTable class="irisePocCardTable">
      <template>
        <div class="tabsFlex">
          <el-tabs v-model="chooseTab" @tab-click="changeTab">
            <el-tab-pane label="已分配权限" name="1"></el-tab-pane>
            <el-tab-pane label="已分配机构" name="2"></el-tab-pane>
          </el-tabs>
          <div class="fex-item-tabs">
            <el-button
              v-if="chooseTab == 1"
              type="primary"
              class="buttonActiveBig"
              style="position: relative; top: -9px"
              :class="orgFlag ? 'greyButton' : ''"
              :disabled="orgFlag"
              @click="distributionOrganization"
              >分配权限</el-button
            >
            <el-button
              v-if="chooseTab == 2"
              type="primary"
              class="buttonActiveBig"
              style="position: relative; top: -9px"
              :class="orgFlag ? 'greyButton' : ''"
              :disabled="orgFlag"
              @click="distributionOrg"
              >分配组织</el-button
            >
          </div>
        </div>
      </template>
      <PocTable
        v-loading="queryLoading"
        :data="tableData"
        stripe
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        :max-height="maxHeight"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          show-overflow-tooltip
        >
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <scopeTransfer
      ref="scopeTransferRef"
      @onConfirm="onConfirm"
      :dialog-title="chooseTab == 1 ? '分配权限' : '分配组织'"
    />
  </div>
</template>

<script>
import { getTheyearOptions, stampToTime } from '@/utils/util'
const { Vuex } = $PCommon
const { mapGetters } = Vuex
import scopeTransfer from '@/components/ScopeTransfer/ScopeTransfer'
import { sysPermissionsGetData } from '@/views/menuRoleManage/Hooks/sysPermissionsGetData'
const { PocResetMessage, $sessionStorage } = $PCommon
const h = document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
import pocSlider from '@/components/poc-slider/poc-slider'
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    scopeTransfer,
    pocSlider
  },

  data() {
    return {
      maxHeight: h,
      queryLoading: false,
      downloadLoading: false,
      deleteLoading: false,
      tableData: [],
      chooseFlag: false,
      scopeData: [],
      upFlag: true,
      scopeFlag: false,
      editCodeFlag: true, //编辑时code不可选择
      pp: '',
      orgNames: [],
      orgName: '',
      orgFlag: false, // 分配按钮默认为可以点击
      columnConfig: [
        {
          key: 'permissionCode',
          name: '权限编码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'permissionName',
          name: '权限名称',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createUser',
          name: '授权人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createTime',
          name: '授权时间',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        }
      ],
      columnConfig2: [
        {
          key: 'orgCode',
          name: '组织编码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'orgName',
          name: '组织名称',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createUser',
          name: '授权人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        },
        {
          key: 'createTime',
          name: '授权时间',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left'
        }
      ],
      selectEditMsg: {
        roleName: '',
        roleCode: '',
        remark: '',
        status: '',
        orgIds: '',
        assignedList: ''
      }, // 选中修改的一系列用户信息
      selectIdArr: [], // 选中数组id集合
      chooseTab: '1', // 默认选中已分配机构
      statusList: [
        { id: 0, name: '启用' },
        { id: 1, name: '停用' }
      ],
      failturesList: [
        { id: 0, name: '未锁定' },
        { id: 1, name: '已锁定' }
      ],
      tabLabel: {
        1: '已分配权限',
        2: '已分配机构'
      },
      jumpFlag: '',
      singleId: '',
      OrgUnAssignList: [],
      OrgAssignList: [],
      TreeSysOrgList: [],
      buttonDisable: false, // 按钮默认可点
      props: { multiple: true },
      cascaderMod: ''
    }
  },
  props: ['jumpParams'],
  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    },
    columnOptions() {
      if (this.chooseTab == 1) {
        return this.columnConfig.filter((item) => item.visible)
      } else {
        return this.columnConfig2.filter((item) => item.visible)
      }
    },
    ...mapGetters(['userInfo'])
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
      this.maxHeight = h
    })
  },

  created() {
    console.log('uuu取参数---', this.jumpParams)
    this.jumpFlag = this.jumpParams.jumpFlag
    this.singleId = this.jumpParams.singleId
    if (
      this.jumpFlag == 'edit' ||
      this.jumpFlag == 'copy' ||
      this.jumpFlag == 'goDetail'
    ) {
      // 请求单个用户的信息
      this.editCodeFlag = true //复制时，不可点击
      this.getsingleMsg(this.singleId)
      this.filterQuery()
    }
    if (!this.singleId) {
      // 新增进来没有id,分配组织机构按钮不可点
      this.orgFlag = true
    }
    // 获取组织机构
    this.getTreeSysOrg()
  },
  methods: {
    filterQuery() {
      this.pageConfig.pageNum = 1

      this.queryApi()
      // this.queryLoading = true;
    },
    onFilter(type, value) {
      console.log('99999999', value)
      this.$forceUpdate()
      this.selectEditMsg.beginTime = value[0]
      this.selectEditMsg.endTime = value[1]
    },
    dealStatus(status) {
      switch (status) {
        case '0': {
          return '有效'
        }
        case '1': {
          return '失效'
        }
        default: {
          return '-'
        }
      }
    },
    // 获取已分配机构
    async queryApi() {
      this.queryLoading = true
      const tab = {
        1: 'getPermissionList',
        2: 'getRoleOrgList'
      }
      console.log(',,,,,,,,参数3', this.jumpFlag, this.singleId)
      const { data, code, msg } = await this.$Api.secretRole[
        tab[this.chooseTab]
      ]({
        roleId: this.singleId,
        ...this.pageConfig
      })
      this.queryLoading = false
      if (code === 200) {
        console.log('898989', data)
        this.tableData = data.list
        this.pageConfig.total = data.total ? data.total : 0
      }
    },
    upRow() {
      this.upFlag = !this.upFlag
    },
    changeTab() {
      this.queryApi()
    },
    async onConfirm(val) {
      if (this.chooseTab == 1) {
        console.log(val)
        this.selectEditMsg.assignedList = val ? val : ''
      } else if (this.chooseTab == 2) {
        this.selectEditMsg.assignedGroupList = val ? val : ''
      }
      await this.submit()
      if (this.jumpFlag == 'edit' || this.jumpFlag == 'goDetail') {
        //编辑进来的话，改一个就可以返回列表
        await this.$emit('goback')
      } else {
        //非编辑就看是否都有填写
        if (
          this.selectEditMsg.assignedList &&
          this.selectEditMsg.assignedGroupList
        ) {
          //如果分配角色和组织都点击过了才返回列表页
          await this.$emit('goback')
        }
      }
    },
    goEdit(row) {
      this.jumpFlag = 'edit'
    },

    // 调出穿梭框
    distributionOrganization() {
      this.$refs.scopeTransferRef.show({
        id: this.singleId || '',
        positions: 'secret',
        treeTitle: ['待分配权限', '已分配权限'],
        placeholder: '请输入权限名称'
      })
    },
    distributionOrg() {
      this.$refs.scopeTransferRef.show({
        id: this.singleId || '',
        positions: 'disOrg',
        treeTitle: ['待分配组织', '已分配机构'],
        checkStrictly: true
      })
    },
    async getTreeSysOrg() {
      const { data, code, msg } = await this.$Api.secretRole.getTreeSysOrg({
        orgName: this.orgName,
        status: 0
      })
      this.queryLoading = false
      if (code === 200) {
        console.log('===', data)
        const func = (dd) => {
          dd.map((d) => {
            d.label = d.name
            d.value = d.id
            if (d.children) {
              func(d.children)
            }
          })
        }
        func(data)

        this.TreeSysOrgList = data
      }
    },
    // 创建楼层
    async submit() {
      this.buttonDisable = true
      const phoneReg = RegExp(/^0?1[3|4|5|7|8|9][0-9]\d{8}$/) // 只能是中文
      const passwordStrongCheck =
        /(?=.*[A-Za-z0-9])(?=.*[A-za-z])(?=.*[0-9])(?=.*[~!@#$%^&*_.]).{8,}/
      const regEmail =
        /^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/

      if (!this.selectEditMsg.roleName) {
        this.$message({
          type: 'error',
          message: '角色名为空!'
        })
        this.buttonDisable = false
        return
      }
      // if (!this.selectEditMsg.roleCode) {
      //   this.$message({
      //     type: 'error',
      //     message: '角色编码为空!'
      //   })
      //   this.buttonDisable = false
      //   return
      // }
      const chineseReg = RegExp(/[\u4E00-\u9FA5]/g)
      if (chineseReg.test(this.selectEditMsg.roleCode)) {
        this.$message({
          type: 'error',
          message: '用户编码不可为中文!'
        })
        this.buttonDisable = false
        return
      }
      // if (!this.selectEditMsg.orgIds) {
      //   this.$message({
      //     type: 'error',
      //     message: '所属组织未选择!'
      //   })
      //   this.buttonDisable = false
      //   return
      // }
      if (this.selectEditMsg.status === '') {
        this.$message({
          type: 'error',
          message: '状态为空!'
        })
        this.buttonDisable = false
        return
      }
      if (this.jumpFlag == 'add' || this.jumpFlag == 'copy') {
        // 新增函数

        console.log('填写应用的信息', this.selectEditMsg)

        const { code, data, msg } = await this.$Api.secretRole.addRole({
          ...this.selectEditMsg,
          id: ''
        })
        this.buttonDisable = false
        this.queryLoading = false
        if (code == 200) {
          if (data) {
            console.log('copy新增接口============', data, this.singleId)
            // data为返回的新增userId的值
            this.orgFlag = false // 此时分配组织按钮可点
            this.jumpFlag = 'saveAssign' // 新增完如点组织按钮 可点.需走edit接口（PS：一样数据不能用户名一样走2次接口）
            this.singleId = data // 赋予编辑id的值
          }
          this.$message({
            type: 'success',
            message: '新增成功!'
          })
        } else {
          this.$message({
            type: 'info',
            message: msg
          })
        }
      } else {
        console.log('怎么会进入编辑呢', this.jumpFlag)
        // 编辑
        const { data, code, msg } = await this.$Api.secretRole.addRole({
          ...this.selectEditMsg,
          id: this.singleId
        })
        this.buttonDisable = false
        if (code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.filterQuery()
        } else {
          this.$message({
            type: 'info',
            message: msg
          })
        }
      }
    },
    async getsingleMsg(singleId) {
      const { data, code, msg } = await this.$Api.secretRole.getSingleMsg({
        id: singleId
      })
      if (data && code === 200) {
        this.selectEditMsg = data
        let orgNamesObj = {}
        console.log('单个数据', this.selectEditMsg.orgNames)
        if (this.selectEditMsg.orgNames) {
          this.selectEditMsg.orgNames.map((d, index) => {
            console.log(d.join('-'))
            orgNamesObj['name'] = d.join('-')
            this.orgNames.push(orgNamesObj)
            this.orgName = this.orgNames[0].name
          })
          console.log('单个数据', this.orgNames[0])
        }

        this.cascaderMod = this.selectEditMsg.orgIds // 渲染所属组织  json.prase()
        this.selectEditMsg.status = Number(data.status)
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    handleChange(value) {
      // cascaderMod
      console.log('=======选择了那几个', value)
      // console.log('选中的所有子节点',this.$refs.parentref.getCheckedNodes(true).map(v=>v.value))
      // let orgidString = []
      // value.map(v => {
      //   orgidString.push(...v)
      // })
      // orgidString = [...new Set(orgidString)].join()
      // this.selectEditMsg.orgIds = orgidString
      this.selectEditMsg.orgIds = value
      console.log('=======', this.selectEditMsg.orgIds)
    },
    // 关闭侧边栏
    cancle() {
      this.selectEditMsg = {
        beginTime: '',
        email: '',
        endTime: '',
        mobile: '',
        remark: '',
        status: '',
        userCode: '',
        userName: ''
      }
      this.$emit('goback')
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  }
}
</script>
<style lang="scss" scoped>
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.upWord {
  color: #ba9765;
  font-size: 14px;
  margin-left: 1px;
  cursor: pointer;
  margin-left: 8px;
}
.scopeItem {
  margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
}
.active {
  color: #303133;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 14px;
  width: 125px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  padding: 0px 0 14px 0;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
.upContainer {
  width: 250px;
  margin-top: 7px;
  justify-content: flex-end;
}
.up {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: rgba(186, 151, 101, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .strangle {
    width: 0;
    height: 0;
    border: 6px solid;
    border-color: transparent transparent #ba9765 transparent;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3px;
    &.down {
      border-color: #ba9765 transparent transparent transparent;
      top: 3px;
    }
  }
}
.drawerPadding {
  height: 83vh;
  padding: 0px 64px 20px 74px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
.drawerTitle,
.drawerTitle span {
  color: #303133;
  font-size: 16px;
  font-weight: bold !important;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.drawerContainer {
  padding: 30px 20px 20px 20px;
  box-sizing: border-box;
}
.drawerPadding {
  padding: 0px 64px 20px 74px;
  box-sizing: border-box;
}
.bottomButton {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottomBox {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 20px;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.buttonActive {
  width: 76px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonActiveBig {
  width: 104px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonUnactive {
  width: 76px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.lableDetail {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent {
  color: #545454;
  font-size: 14px;
  // font-weight: 300;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  // border: 1px solid red;
  width: 100%;
}
.ellistip {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 94px);
  /* line-height: 12px; */
  vertical-align: middle;
}
.tabsFlex {
  position: relative;
  margin-top: 10px;
  .fex-item-tabs {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
<style lang="scss">
.roleadd-list-page {
  .el-button + .el-button {
    margin-left: 16px;
  }

  .irisePocCardTable {
    //height: 100%;
    .el-card__header {
      height: 65px;
    }
  }

  .el-cascader__tags .el-tag {
    max-width: 70%;
  }
}
.userMan {
  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #868686;
}
.selfUser {
  .el-card__header {
    padding-bottom: 7px !important;
    //padding: 20px 20px 10px 20px !important;
    //margin-bottom: 0 !important;
  }
  .el-card__body {
    //margin-top: -10px;
  }
}
</style>
