<template>
  <div>
    <div v-show="!manageShow" class="role-list-page">
      <PocCardTable class="irisePocCardTable topPocCardTable">
        <template v-slot:headerTitle>角色列表</template>
        <template v-slot:headerGroup>
          <el-input
            style="width: 190px; margin-right: 16px"
            prefix-icon="el-icon-search"
            placeholder="搜索角色名称"
            v-model="filterConfig.roleName"
            @input="inputPermissionName"
          />
          <el-button
            v-if="selectIds.length"
            @click="lockRow(selectIds, '1', '停用')"
            role="disableStatus"
            v-permission
            >停用</el-button
          >
          <el-button
            v-if="selectIds.length"
            @click="lockRow(selectIds, '1', '启用')"
            role="disableStatus"
            v-permission
            >启用</el-button
          >
          <el-button :loading="queryLoading" @click="filterQuery"
            >查询</el-button
          >
          <el-button
            :loading="exportLoading"
            @click="exportData"
            role="export"
            v-permission
            >导出</el-button
          >

          <el-button type="primary" @click="addUser" role="save" v-permission
            >新增角色</el-button
          >
        </template>
        <PocTable
          v-loading="queryLoading"
          :data="tableData"
          stripe
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
          :max-height="100000000"
          @selection-change="handleSelectionChange"
          @row-click="goDetail"
          ref="multipleTable"
        >
          <el-table-column type="selection" width="45"> </el-table-column>
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
          >
            <template slot-scope="scope">
              <div class="orgName" v-if="item.key == 'orgName'">
                <el-tooltip placement="top-start">
                  <div slot="content">
                    <div
                      v-for="(item, index) in scope.row['orgNames']"
                      :key="index"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <div>{{ scope.row[item.key] }}</div>
                </el-tooltip>
              </div>
              <div v-else>
                <el-tooltip placement="top-start">
                  <div slot="content">{{ scope.row[item.key] }}</div>
                  <div>{{ scope.row[item.key] }}</div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-row
                :gutter="16"
                style="display: flex; justify-content: center"
              >
                <el-col :span="8">
                  <!-- <el-tooltip
                    v-if="scope.row.status == '停用'"
                    content="信息已停用"
                    placement="top"
                  >
                    <img
                      class="iconImg"
                      role="save"
                      v-permission
                      src="@/assets/img/grey-edit.png"
                      alt=""
                    />
                  </el-tooltip> -->
                  <el-tooltip content="编辑" placement="top">
                    <img
                      class="iconImg pointer"
                      role="save"
                      v-permission
                      src="@/assets/img/edit.png"
                      @click="editdRow(scope.row)"
                      alt=""
                    />
                  </el-tooltip>
                </el-col>
                <el-col :span="8">
                  <el-tooltip
                    v-if="scope.row.status == '停用'"
                    content="取消停用"
                    placement="top"
                  >
                    <img
                      class="iconImg pointer"
                      role="enableStatus"
                      v-permission
                      src="@/assets/img/lock.png"
                      @click.stop="lockRow(scope.row)"
                      alt=""
                    />
                  </el-tooltip>
                  <el-tooltip
                    v-if="scope.row.status == '启用'"
                    content="停用"
                    placement="top"
                  >
                    <img
                      class="iconImg pointer"
                      role="disableStatus"
                      v-permission
                      src="@/assets/img/prohibit.png"
                      @click.stop="lockRow(scope.row)"
                      alt=""
                    />
                  </el-tooltip>
                </el-col>
                <el-col :span="8">
                  <!-- v-if="scope.row.status == '启用'" -->
                  <el-tooltip content="删除" placement="top">
                    <img
                      class="iconImg"
                      role="delete"
                      v-permission
                      src="@/assets/img/delete.png"
                      @click.stop="deleteRow(scope.row)"
                      alt=""
                    />
                  </el-tooltip>
                  <!-- <el-tooltip
                    v-if="scope.row.status == '停用'"
                    content="停用，无法删除"
                    placement="top"
                  >
                    <img
                      class="iconImg"
                      role="delete"
                      v-permission
                      src="@/assets/img/grey-delete.png"
                      alt=""
                    />
                  </el-tooltip> -->
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </PocTable>
      </PocCardTable>
    </div>
    <add-role-manage
      v-if="manageShow"
      :jumpParams="jumpParams"
      @goback="goback"
    />
    <el-dialog
      :visible.sync="ModalFlagSuccess"
      width="354px"
      height="200px"
      top="230px"
      title="导出提示"
    >
      <div style="text-align: center; font-size: 20px">
        {{ exportMsg }}已成功导出！
      </div>
      <div style="text-align: right; margin-top: 38px">
        <el-button type="primary" @click="close" style="margin-bottom: 20px"
          >知道了（{{ exportLeftSecond }}S）</el-button
        >
        <div class="clear"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTheyearOptions, stampToTime } from '@/utils/util'
const { Vuex } = $PCommon
const { mapGetters } = Vuex
import addRoleManage from './addRoleManage.vue'
const { PocResetMessage, $sessionStorage } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    addRoleManage
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 250 + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      exportLoading: false,
      downloadLoading: false,
      deleteLoading: false,
      ModalFlagSuccess: false,
      exportLeftSecond: 3,
      exportMsg: '',
      inputPermissionName: '',
      tableData: [],
      chooseFlag: false,
      scopeData: [],
      columnConfig: [
        {
          key: 'roleCode',
          name: '角色编码',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'roleName',
          name: '角色名称',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        },
        {
          key: 'orgName',
          name: '所属组织',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'createInfo',
          name: '创建信息',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        },
        {
          key: 'status',
          name: '启用状态',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        }
      ],
      filterConfig: {
        roleName: ''
      },
      selectIds: [], // 选中数组id集合字符串
      statusList: [
        { id: '0', name: '启用' },
        { id: '1', name: '停用' }
      ],
      failturesList: [
        { id: '0', name: '未锁定' },
        { id: '1', name: '已锁定' }
      ],
      props: { multiple: true },
      manageShow: false,
      jumpParams: {},
      firstSelectItem: {},
      selectedItems: []
    }
  },

  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    },
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    },
    ...mapGetters(['userInfo'])
  },

  created() {
    this.filterQuery()
  },
  methods: {
    goback() {
      this.manageShow = false
      this.filterQuery()
    },
    close() {
      this.ModalFlagSuccess = false
      this.exportLeftSecond = 3
    },
    filterQuery() {
      console.log(',,,,,,,,参数', this.filterConfig)
      this.pageConfig.pageNum = 1
      this.queryApi()
      // this.queryLoading = true;
    },
    dealStatus(status) {
      switch (status) {
        case '0': {
          return '有效'
        }
        case '1': {
          return '失效'
        }
        default: {
          return '-'
        }
      }
    },
    // 获取基础数据维护的列表
    async queryApi() {
      this.queryLoading = true
      const { data, code } = await this.$Api.secretRole.getDataList({
        ...this.filterConfig,
        ...this.pageConfig
      })
      this.queryLoading = false
      if (code === 200) {
        if (data.list) {
          data.list.map((d) => {
            d.orgNames = d.orgName ? d.orgName.split(',') : ''
          })
          this.tableData = data.list
          this.pageConfig.total = data.total
        } else {
          this.tableData = []
          this.pageConfig.total = 0
        }

        console.log('角色列表-----', this.tableData)

        this.$forceUpdate()
      }
    },
    handleSelectionChange(val) {
      if (val.length) {
        this.ifSureFlag = false
        this.selectedItems = val
        this.selectIds = this.selectedItems.map((v) => v.id).join()
      } else {
        this.ifSureFlag = true
      }
    },
    goDetail(row) {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'goDetail', singleId: row.id }
    },
    async exportData() {
      console.log('导出------')
      // this.pageConfig.total = 0
      this.exportLoading = true
      // await this.queryApi()
      if (this.pageConfig.total === 0) {
        this.$message({
          type: 'error',
          message: '角色数据为空，无法导出'
        })
        this.queryLoading = false
        return
      }
      await this.$Api.secretRole.exportDataList2({
        ...this.filterConfig,
        ...this.pageConfig,
        ids: this.selectedItems.map((item) => item.id).toString()
      })
      this.exportLoading = false
      // this.exportShowTip()
    },
    exportShowTip() {
      this.exportMsg = '角色数据'
      this.ModalFlagSuccess = true
      this.exportLeftSecond = 3
      const timer = setInterval(() => {
        if (this.exportLeftSecond > 0) {
          this.exportLeftSecond--
        } else {
          clearInterval(timer)
          this.ModalFlagSuccess = false
        }
      }, 1000)
    },
    deleteRow(index) {
      const rowId = index.id
      this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.getDeleteReason(rowId)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 删除原因，子组件回调
    async getDeleteReason(ids) {
      const { code } = await this.$Api.secretRole.deleteRow({
        ids
      })
      if (code == 200) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.queryApi()
      }
    },
    // 取消停用
    async lockRow(row, chooseMore, chooseMorestatus) {
      let id = ''
      let rowStatus = ''
      if (chooseMore == 1) {
        console.log('多选功能', chooseMorestatus)
        // 多选功能
        // chooseMorestatus传过来的操作动作，不是列表状态与row.status相反
        id = this.selectIds
        rowStatus = chooseMorestatus
        let status = ''
        let msg = ''
        if (rowStatus == '启用') {
          status = 0
          msg = '取消停用成功!'
        } else {
          status = 1
          msg = '停用成功!'
        }
        const { code, data } = await this.$Api.secretRole.changeRowStatus({
          id,
          status
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: msg
          })
          this.queryApi() // 刷新下界面
        }
      } else {
        id = row.id
        rowStatus = row.status
        let status = ''
        let msg = ''
        if (rowStatus == '启用') {
          // 有效状态，需要停用
          status = 1
          msg = '停用成功!'
        } else {
          status = 0
          msg = '取消停用成功!'
        }
        const { code, data } = await this.$Api.secretRole.changeRowStatus({
          id,
          status
        })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: msg
          })
          this.queryApi() // 刷新下界面
        }
      }
    },
    async addUser() {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'add' }

      // await this.getscopeList()
      // const timestamp = Date.parse(new Date())
      // this.nowDate = stampToTime(timestamp)
      // this.drawer3 = true
      // this.addFlag = true
      // this.selectEditMsg = {
      //   appName: '',
      //   appCode: '',
      //   clientId: '',
      //   clientSecret: '',
      //   remoteUrl: '',
      //   scope: '',
      //   remark: '',
      //   status: 0 // 默认新增
      // } // 选中修改的一系列用户信息;
      // this.value = ''
    },
    scopeRow(row) {
      // 获取弹出层组织机构待分配列表
      this.getScopeDataList()
    },
    async getScopeDataList() {
      const { code, data } = await this.$Api.secretRole.getOrgUnAssignList({
        id
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: msg
        })
        console.log('909909', data)
      }
    },

    // 编辑信息
    editdRow(row) {
      console.log('333333', row)
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'edit', singleId: row.id }
    },
    // 复制信息
    copy(row) {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'copy', singleId: row.id }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 250 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="scss">
.role-list-page {
  .el-button + .el-button {
    margin-left: 16px;
  }
  .buttonUnactive {
    width: 76px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ba9765;
    border-radius: 4px;
    color: #ba9765;
    font-size: 14px;
    line-height: 15px;
  }
  .buttonActive {
    width: 104px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 15px;
  }
  .irisePocCardTable {
    //height: 100%;
    .el-card__header {
      height: 65px;
    }
  }
}
</style>
<style lang="scss" scoped>
.role-list-page {
  .el-tooltip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .cell {
    max-height: 39px !important;
    overflow: hidden !important;
  }
}
</style>
